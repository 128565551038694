<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.weather-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="weather" :model="weather" label-position="right" label-width="150px" class="form-settings" style="width: 500px">

      <el-form-item :label="$t('settings.general.weather-type')">
        <el-select v-model="weather.unit" filterable :placeholder="$t('settings.general.select-type')">
          <el-option v-for="(type, index) in ['f', 'c']" :key="index" :label="(type == 'f' ? $t('settings.general.fahrenheit') : $t('settings.general.centigrade'))" :value="type"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('settings.general.polling-period')">
        <el-input type="number" :min="5" v-model="weather.pollingPeriod" :placeholder="$t('settings.general.type-period')">
          <span slot="append">{{ $t('settings.general.min') }}</span>
        </el-input>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
  export default {
    name: "WeatherSettings",

    data: function(){
      return {
        showSaveButton: false
      }
    },

    computed: {
      weather: function () {
        return this.$store.getters.generalSettingsFormData.weather
      }
    },

    watch: {
      weather: {
        handler: function (val) {
          this.showSaveButton = true
        },
        deep: true
      },
      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }
    },

    props: {
      loading: Boolean
    },

    methods: {
      onSave: function () {
        this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
          weather: {...this.weather}}).then(response => {

          this.$message({ type: "success", message: this.$t(response.message) })
          this.showSaveButton = false
        }).catch(reason => {
          this.$message({ type: "error", message: this.$t(reason) })
          this.showSaveButton = false
        })
      }
    },

  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>