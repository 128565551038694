var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.general.project-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSaveProject }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.project
        ? _c(
            "el-form",
            {
              ref: "projectData",
              staticClass: "form-settings",
              attrs: {
                model: _vm.project,
                "label-position": "right",
                "label-width": "130px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("settings.general.project-name"),
                    prop: "projectName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("settings.general.type-project")
                    },
                    model: {
                      value: _vm.project.projectName,
                      callback: function($$v) {
                        _vm.$set(_vm.project, "projectName", $$v)
                      },
                      expression: "project.projectName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("settings.general.block-name"),
                    prop: "blockName"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("settings.general.type-block")
                    },
                    model: {
                      value: _vm.project.blockName,
                      callback: function($$v) {
                        _vm.$set(_vm.project, "blockName", $$v)
                      },
                      expression: "project.blockName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("settings.general.floor-number"),
                            prop: "floorNumber"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              placeholder: _vm.$t(
                                "settings.general.floor-number"
                              )
                            },
                            model: {
                              value: _vm.project.floorNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "floorNumber", $$v)
                              },
                              expression: "project.floorNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("settings.general.flat-number"),
                            prop: "flatNumber"
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              placeholder: _vm.$t(
                                "settings.general.flat-number"
                              )
                            },
                            model: {
                              value: _vm.project.flatNumber,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "flatNumber", $$v)
                              },
                              expression: "project.flatNumber"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("settings.general.location") }
                        },
                        [
                          _c("vue-google-autocomplete", {
                            ref: "address",
                            attrs: {
                              id: "map",
                              types: "(cities)",
                              value: _vm.project.location,
                              classname: "el-input__inner",
                              placeholder: _vm.$t(
                                "settings.general.type-city-name"
                              )
                            },
                            on: {
                              inputChange: _vm.onAddressChanged,
                              placechanged: _vm.getAddressData
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: { gutter: 20 }
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("settings.general.contact-name"),
                            prop: "contactName"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "settings.general.type-contact-name"
                              )
                            },
                            model: {
                              value: _vm.project.contactName,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "contactName", $$v)
                              },
                              expression: "project.contactName"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("settings.general.contact-phone"),
                            prop: "contactPhone"
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: _vm.$t(
                                "settings.general.type-contact-phone"
                              )
                            },
                            model: {
                              value: _vm.project.contactPhone,
                              callback: function($$v) {
                                _vm.$set(_vm.project, "contactPhone", $$v)
                              },
                              expression: "project.contactPhone"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }