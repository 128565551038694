var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.general.knx-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.knxSettings
        ? _c(
            "el-form",
            {
              ref: "projectData",
              staticClass: "form-settings",
              attrs: {
                model: _vm.knxSettings,
                "label-position": "right",
                "label-width": "150px"
              }
            },
            [
              false
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: {
                        label: _vm.$t("settings.general.discard-knx-bus-errors")
                      }
                    },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.knxSettings.discardBusErrors,
                          callback: function($$v) {
                            _vm.$set(_vm.knxSettings, "discardBusErrors", $$v)
                          },
                          expression: "knxSettings.discardBusErrors"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "300px" },
                  attrs: { label: _vm.$t("settings.general.source-address") }
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.groupAddressMask,
                        expression: "groupAddressMask"
                      }
                    ],
                    attrs: { type: "text", placeholder: "x.y.z" },
                    model: {
                      value: _vm.knxSettings.tpuartSourceAddress,
                      callback: function($$v) {
                        _vm.$set(_vm.knxSettings, "tpuartSourceAddress", $$v)
                      },
                      expression: "knxSettings.tpuartSourceAddress"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }