<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.date-time-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="dateTime" :model="dateTime" label-position="right" label-width="150px" class="form-settings" style="width: 500px" >

      <el-form-item :label="$t('settings.general.gmt')">
        <el-select v-model="dateTime.currentGMT" filterable :placeholder="$t('settings.general.select-gmt')">
          <el-option v-for="(timezone, index) in dateTime.availableTimeZones" :key="index" :label="timezone.title" :value="timezone.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-show="false" :label="$t('settings.general.ntp-server')">
        <el-input type="text" v-model="dateTime.ntpServer" :placeholder="$t('settings.general.type-server')">
          <span slot="prepend">http://</span>
        </el-input>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
  export default {
    name: "DateTimeSettings",

    computed: {
      dateTime: function () {
        return this.$store.getters.generalSettingsFormData.dateTime
      }
    },

    watch: {
      dateTime: {
        handler: function (val) {
          this.showSaveButton = true
        },
        deep: true
      },
      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }
    },

    props: {
      loading: Boolean
    },

    data: function () {
      return {
        showSaveButton: false
      }
    },


    methods: {
      onSave: function () {
        this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
          dateTime: {...this.dateTime}}).then(response => {

          this.$message({ type: "success", message: this.$t(response.message) })
          this.showSaveButton = false
        }).catch(reason => {
          this.$message({ type: "error", message: this.$t(reason) })
          this.showSaveButton = false
        })
      }
    },
  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>