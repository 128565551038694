var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.general.screensaver-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.screenSaver
        ? _c(
            "el-form",
            {
              ref: "projectData",
              staticClass: "form-settings",
              staticStyle: { width: "500px" },
              attrs: {
                model: _vm.screenSaver,
                "label-position": "right",
                "label-width": "200px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("settings.general.screensaver-type") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-type")
                      },
                      model: {
                        value: _vm.screenSaver.type,
                        callback: function($$v) {
                          _vm.$set(_vm.screenSaver, "type", $$v)
                        },
                        expression: "screenSaver.type"
                      }
                    },
                    _vm._l(_vm.types, function(type, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: type.title, value: type.value }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("settings.general.screensaver-delay") }
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-delay")
                      },
                      model: {
                        value: _vm.screenSaver.delayTime,
                        callback: function($$v) {
                          _vm.$set(_vm.screenSaver, "delayTime", $$v)
                        },
                        expression: "screenSaver.delayTime"
                      }
                    },
                    _vm._l(_vm.delays, function(delay, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: delay + " " + _vm.$t("settings.general.min"),
                          value: delay
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: false,
                      expression: "false"
                    }
                  ],
                  attrs: { label: _vm.$t("settings.general.screensaver-pwd") }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      placeholder: _vm.$t("settings.general.type-password")
                    },
                    model: {
                      value: _vm.screenSaver.password,
                      callback: function($$v) {
                        _vm.$set(_vm.screenSaver, "password", $$v)
                      },
                      expression: "screenSaver.password"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }