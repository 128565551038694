<template>
  <div class="root-layout">

    <app-navigation :title="$t('settings.general.general-settings')"></app-navigation>

    <div v-loading="loading" v-if="loading" style="width: 100%; height: 100%">

    </div>

    <project-settings :loading="loading"></project-settings>

    <device-settings v-show="!$store.getters.isConnectedDevice" :loading="loading"
      style="margin-top: 30px"></device-settings>

    <device-settings-remote v-show="$store.getters.isConnectedDevice" :loading="loading"
      style="margin-top: 30px"></device-settings-remote>

    <screensaver-settings :loading="loading" style="margin-top: 30px"></screensaver-settings>

    <weather-settings :loading="loading" style="margin-top: 30px"></weather-settings>

    <ringtone-settings ref="ringtoneForm" :loading="loading" style="margin-top: 30px"></ringtone-settings>

    <date-time-settings :loading="loading" style="margin-top: 30px"></date-time-settings>

    <knx-settings v-if="existsKNXTP" :loading="loading" style="margin-top: 30px"></knx-settings>

  </div>
</template>

<script>
import Navigation from '@/components/Navigation';
import ProjectSettings from "./components/ProjectSettings"
import RingtoneSettings from "./components/RingtoneSettings"
import ScreensaverSettings from "./components/ScreensaverSettings"
import WeatherSettings from "./components/WeatherSettings"
import DeviceSettings from "./components/DeviceSettings"
import DeviceSettingsRemote from "./components/DeviceSettingsRemote"
import DateTimeSettings from "./components/DateTimeSettings"
import KnxSettings from "./components/KnxSettings"
import { initializeWebSocket } from "@/utils/socket-utils"

export default {
  name: "General",

  components: {
    appNavigation: Navigation,
    projectSettings: ProjectSettings,
    ringtoneSettings: RingtoneSettings,
    screensaverSettings: ScreensaverSettings,
    weatherSettings: WeatherSettings,
    deviceSettings: DeviceSettings,
    deviceSettingsRemote: DeviceSettingsRemote,
    dateTimeSettings: DateTimeSettings,
    knxSettings: KnxSettings,
  },

  data: function () {
    return {
      project: {},

      ringtone: {
      },

      screenSaver: {
        type: "",
        delayTime: 1,
        password: "",
        types: [
          "None (Energy Saver)", "Clock", "Background"
        ]
      },

      weather: {
      },

      device: {
        language: "tr",
        background: {
          imageUrl: "",
          blur: true
        }
      },

      dateTime: {
        availableTimeZones: [
          { title: "GMT + 1", value: "gmt1" },
          { title: "GMT + 2", value: "gmt2" },
          { title: "GMT + 3", value: "gmt3" },
          { title: "GMT + 4", value: "gmt4" },
          { title: "GMT + 5", value: "gmt5" },
          { title: "GMT + 6", value: "gmt6" },
          { title: "GMT + 7", value: "gmt7" },
          { title: "GMT + 8", value: "gmt8" },
          { title: "GMT + 9", value: "gmt9" },
          { title: "GMT + 10", value: "gmt10" },
          { title: "GMT + 11", value: "gmt11" },
        ]
      },

      loading: true
    }
  },

  computed: {
    existsKNXTP: function () {
      let productCode = this.$store.getters.productCode
      return productCode.includes("KNX")
    },
    isConnected() {
      return this.$store.getters.isConnected;
    },
  },

  watch: {
    project: function (value) {
      console.log("chance project", value)
    },
    isConnected(value) {
      if (value) {
        this.fetchData();
      }
    }
  },

  methods: {
    async fetchData() {
      try {
        await this.$store.dispatch("getGeneralSettings");
        this.$store.commit("setGeneralSettingsFormData", {
          project: { ...this.$store.getters.project },
          weather: { ...this.$store.getters.weather },
          screenSaver: { ...this.$store.getters.screenSaver },
          device: { ...this.$store.getters.device },
          ringtone: { ...this.$store.getters.ringtone },
          dateTime: { ...this.$store.getters.dateTime },
          knxSettings: { ...this.$store.getters.knxSettings }
        })

        this.$refs.ringtoneForm.onLoad()
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    }
  },

  created() {
    this.loading = true;
    if (!this.isConnected) {
      initializeWebSocket()
    }
    this.fetchData()
  }

}
</script>

<style scoped>
@import "../css/general.css";

.root-layout {
  padding: 30px;
}</style>