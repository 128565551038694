<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.screensaver-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="screenSaver" ref="projectData" :model="screenSaver" label-position="right" label-width="200px" class="form-settings" style="width: 500px">

      <el-form-item :label="$t('settings.general.screensaver-type')">
        <el-select v-model="screenSaver.type" filterable :placeholder="$t('settings.general.select-type')">
          <el-option v-for="(type, index) in types" :key="index" :label="type.title" :value="type.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$t('settings.general.screensaver-delay')">
        <el-select v-model="screenSaver.delayTime" filterable :placeholder="$t('settings.general.select-delay')">
          <el-option v-for="(delay, index) in delays" :key="index" :label="delay + ' ' + $t('settings.general.min')" :value="delay"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-show="false" :label="$t('settings.general.screensaver-pwd')">
        <el-input type="password" v-model="screenSaver.password" :placeholder="$t('settings.general.type-password')"></el-input>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
  export default {
    name: "ScreensaverSettings",

    data: function(){
      return {
        showSaveButton: false,
        delays: [1, 5, 10, 20, 30, 40, 50, 60],
        types: [
          { title: this.$t('settings.general.screensaver-types[0]'), value: 1},
          // { title: this.$t('settings.general.screensaver-types[1]'), value: 2},
          // { title: this.$t('settings.general.screensaver-types[2]'), value: 3}
        ]
      }
    },

    computed: {
      screenSaver: function () {
        return this.$store.getters.generalSettingsFormData.screenSaver
      }
    },

    watch: {
      screenSaver: {
        handler: function (val) {
          this.showSaveButton = true
        },
        deep: true
      },

      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }
    },

    props: {
      loading: Boolean
    },

    methods: {
      onSave: function () {
        this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
          screenSaver: {...this.screenSaver}}).then(response => {

          this.$message({ type: "success", message: this.$t(response.message) })
          this.showSaveButton = false
        }).catch(reason => {
          this.$message({ type: "error", message: this.$t(reason) })
          this.showSaveButton = false
        })
      }
    },
  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>