<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.device-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="device" :model="device" label-position="right" label-width="150px" class="form-settings" style="width: 500px">

      <el-form-item :label="$t('settings.general.device-name')">
        <el-input v-model="device.name" :placeholder="$t('settings.general.type-device-name')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('settings.general.language')">
        <el-select v-model="device.language" filterable :placeholder="$t('settings.general.select-lang')">
          <el-option v-for="(type, index) in ['tr', 'en']" :key="index" :label="type + (type == 'tr' ? ' (Turkish)' : ' (English)')" :value="type"></el-option>
        </el-select>
      </el-form-item>



      <el-row>
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.bg-image')">

            <el-upload ref="upload"
                       class="background-uploader"
                       action="upload.php"
                       :on-success="handleUploadSuccess"
                       :on-change="handleChange"
                       :on-remove="handleRemove"
                       :on-error="handleError"
                       :auto-upload="false"
                       :file-list="fileList">

              <el-button slot="trigger" size="small" type="primary">{{ $t('settings.general.select-file') }}</el-button>
              <!--<el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">upload to server</el-button>-->
              <div class="el-upload__tip" slot="tip"><i @click="onRemoveBackground" v-if="fileList.length == 0 && device.background.name" class="fas fa-minus-circle remove-background"></i> {{device.background.name}}</div>

              <!--<img slot="trigger" v-if="device.background.imageUrl" :src="device.background.name" alt="" class="background">-->
              <!--<i slot="trigger" v-else class="el-icon-plus background-uploader-icon"></i>-->

            </el-upload>

          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.blur')">
            <el-switch v-model="device.background.blur"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>



      <el-row v-if="false">
        <el-col style="width:10%">
          <el-form-item :label="$t('settings.general.is-slave')">
            <el-switch v-model="device.isSlave"/>
          </el-form-item>
        </el-col>
        <el-col style="width:90%">
          <el-form-item v-show="device.isSlave">
            <el-input :placeholder="$t('settings.general.master-ip')" v-mask="ipMask" v-model="device.masterIp"/>
          </el-form-item>
        </el-col>
      </el-row>

      <el-dialog :title="$t('alert.title.warning')" :visible.sync="showDialog" @visiblechange="updateShowDialog">
        <span>{{ $t('settings.general.slave-mode-message') }}</span>
        <span slot="footer">
            <el-button @click="showDialog = false">{{ $t('alert.button.cancel') }}</el-button>
            <el-button type="primary" @click="saveDeviceSettings">{{ $t('alert.button.ok') }}</el-button>
          </span>
      </el-dialog>


    </el-form>
    <el-row justify="center">
      <el-col :span="24">
        <el-alert :title="$t('settings.general.select-file-warning')" type="info" :closable="false" class="alert-image"
          show-icon>
        </el-alert>
        <span></span>
      </el-col>
    </el-row>

  </div>
</template>

<script>
  export default {
    name: "DeviceSettings",

    data: function(){
      return {
        fileList: [],
        blur: false,
        showSaveButton: false,
        showDialog: false,
        ipMask: {
          mask: "9{1,3}.9{1,3}.9{1,3}.9{1,3}"
        }
      }
    },

    props: {
      loading: Boolean
    },

    computed: {
      device: function () {
        return this.$store.getters.generalSettingsFormData.device
      },

      // fileList: function () {
      //   return [ {name: this.device.background.name, url: this.device.background.imageUrl} ]
      // }
    },

    watch: {
      device: {
        handler: function (val) {
          this.showSaveButton = true

        },
        deep: true
      },
      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }
    },


    methods: {
      onRemoveBackground(){
        this.device.background.imageUrl = ""
        this.device.background.name = ""
      },

      handleUploadSuccess(res, file){
        console.log(res, file)
        this.$refs.upload.clearFiles()

        this.saveDeviceSettings()
      },

      handleChange(file, fileList){
        //console.log(file, fileList)
        this.fileList = fileList.slice(fileList.length - 1)
        this.device.background.imageUrl = URL.createObjectURL(file.raw)
        this.device.background.name = file.name

        console.log(this.device.background, this.fileList)

      },

      handleRemove(file, fileList){
        this.fileList = []
        this.device.background.imageUrl = ""
        this.device.background.name = ""
      },

      handleError(err, file, fileList){

        this.$message({ type: "error", message: this.$t('settings.general.error-occured') })
      },

      onBlur(e){
        console.log(e)
      },

      beforeUpload(file){
        return true
      },

      onSave(){
        console.log("save device", this.device, this.fileList);

        if (this.fileList.length > 0) {
          this.$refs.upload.submit()
        } else {
          if(this.device.isSlave){
            if(this.device.masterIp.length > 0)
              this.showDialog = true
          } else {
            this.saveDeviceSettings()
          }
        }
      },

      saveDeviceSettings(){
        this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
          device: {...this.device}}).then(response => {

          this.$message({ type: "success", message: this.$t(response.message) })
          this.showSaveButton = false
          this.showDialog = false;
        }).catch(reason => {
          this.$message({ type: "error", message: this.$t(reason) })
          this.showSaveButton = false
          this.showDialog = false;
        })
      }
    },

  }
</script>

<style scoped>
  @import "../../css/general.css";

  .background-uploader {
    width: 360px;
  }
  .background-uploader:hover {
    border-color: #409EFF;
  }
  .background-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 180px;
    height: 180px;
    line-height: 180px;
    text-align: center;
  }

  .background {
    width: 180px;
    height: 180px;
    display: block;
  }

  .remove-background {
    cursor: pointer;
    color: #dd6161;
  }

</style>