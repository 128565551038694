<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.project-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSaveProject"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="project" ref="projectData" :model="project" label-position="right" label-width="130px" class="form-settings">

      <el-form-item :label="$t('settings.general.project-name')" prop="projectName">
        <el-input type="text" v-model="project.projectName" :placeholder="$t('settings.general.type-project')"></el-input>
      </el-form-item>

      <el-form-item :label="$t('settings.general.block-name')" prop="blockName">
        <el-input type="text" v-model="project.blockName" :placeholder="$t('settings.general.type-block')"></el-input>
      </el-form-item>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.floor-number')" prop="floorNumber">
            <el-input-number v-model="project.floorNumber" :placeholder="$t('settings.general.floor-number')"></el-input-number>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.flat-number')" prop="flatNumber">
            <el-input-number :min="0" v-model="project.flatNumber" :placeholder="$t('settings.general.flat-number')"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>


      <el-row :gutter="20">
        <el-col>
          <el-form-item :label="$t('settings.general.location')">
            <vue-google-autocomplete
                    id="map"
                    ref="address"
                    types="(cities)"
                    :value="project.location"
                    classname="el-input__inner"
                    :placeholder="$t('settings.general.type-city-name')"
                    v-on:inputChange="onAddressChanged"
                    v-on:placechanged="getAddressData">
            </vue-google-autocomplete>
          </el-form-item>

        </el-col>
      </el-row>


      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.contact-name')" prop="contactName">
            <el-input type="text" v-model="project.contactName" :placeholder="$t('settings.general.type-contact-name')"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item :label="$t('settings.general.contact-phone')" prop="contactPhone">
            <el-input type="text" v-model="project.contactPhone" :placeholder="$t('settings.general.type-contact-phone')"></el-input>
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>
  </div>
</template>

<script>

  import VueGoogleAutocomplete from 'vue-google-autocomplete'

  export default {
    name: "ProjectSettings",

    components: { VueGoogleAutocomplete },

    data: function () {
      return {
        showSaveButton: false,

        country: "",
        state: "",
        city: "",
        latitude: "",
        longitude: ""
      }
    },

    props: {
      loading: Boolean
    },

    computed: {
      project: function () {
        return this.$store.getters.generalSettingsFormData.project
      }
    },


    watch: {
      project: {
        handler: function (val1, val2) {
          console.log("project changed : " + this.project.location)

          this.showSaveButton = true

        },
        deep: true
      },

      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }

    },
    
    methods: {

      onAddressChanged: function(data){
        this.project.location = data.newVal
      },

      getAddressData: function (addressData, placeResultData, id) {
        console.log(addressData, placeResultData, id)

        this.latitude = addressData.latitude
        this.longitude = addressData.longitude


        var components = placeResultData.address_components;

        var countryAddr = components.find(address => address.types.includes("country"))
        if (countryAddr)
          this.country = countryAddr.long_name

        var stateAddr = components.find(address => address.types.includes("administrative_area_level_1"));
        if (stateAddr) this.state = stateAddr.long_name
        else this.state = this.country

        var cityAddr = components.find(address => address.types.includes("administrative_area_level_2"));
        if (cityAddr) this.city = cityAddr.long_name


        var addressMap = {}
        components.forEach(address => {
          addressMap[address.types[0]] = address.long_name
        })

        this.project.addressComponents = addressMap

        console.log(this.country, this.state, this.city)
        console.log(addressMap)
      },


      onSaveProject: function () {

        if (!this.country) {
          this.project.addressComponents = undefined

          var items = this.project.location.split(",")

          if (items.length > 0) {
            this.country = items[items.length - 1].trim()

            if (items.length > 1) this.state = items[items.length - 2].trim()
            else this.state = this.country

            if (items.length > 2) this.city = items[items.length - 3].trim()
          }
        }


        this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
          project: {...this.project, country: this.country, state: this.state, city: this.city, latitude: this.latitude, longitude: this.longitude}}).then(response => {
            this.reset()
            this.$message({ type: "success", message: this.$t(response.message) })
            this.showSaveButton = false
        }).catch(reason => {
            this.reset()
            this.$message({ type: "error", message: this.$t(reason) })
            this.showSaveButton = false
        })
      },

      reset: function () {
        this.country = ""
        this.state = ""
        this.city = ""
        this.latitude = ""
        this.longitude = ""
      }

    },



    created(){
      console.log("project created", this.project)
    },

  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>