var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$t("settings.general.ringtone-settings")) +
            "\n    "
        ),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.ringtone
        ? _c(
            "el-form",
            {
              ref: "projectData",
              staticClass: "form-settings",
              attrs: {
                model: _vm.ringtone,
                "label-position": "right",
                "label-width": "200px"
              }
            },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: { label: _vm.$t("settings.general.doorbell-enable") }
                },
                [
                  _c("el-switch", {
                    attrs: {
                      disabled: _vm.availableInputs.length <= 0,
                      "active-text":
                        _vm.availableInputs.length > 0
                          ? ""
                          : _vm.$t("settings.general.no-iddle-port")
                    },
                    on: { change: _vm.onDoorbellStateChanged },
                    model: {
                      value: _vm.ringtone.enableDoorbell,
                      callback: function($$v) {
                        _vm.$set(_vm.ringtone, "enableDoorbell", $$v)
                      },
                      expression: "ringtone.enableDoorbell"
                    }
                  })
                ],
                1
              ),
              _vm.ringtone.enableDoorbell
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: {
                        label: _vm.$t("settings.general.doorbell-driver")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("accessory.select-driver")
                          },
                          on: { change: _vm.onDriverSelect },
                          model: {
                            value: _vm.ringtone.doorbellDriver,
                            callback: function($$v) {
                              _vm.$set(_vm.ringtone, "doorbellDriver", $$v)
                            },
                            expression: "ringtone.doorbellDriver"
                          }
                        },
                        _vm._l(_vm.doorbellDrivers, function(driver, index) {
                          return _c(
                            "el-option",
                            {
                              key: driver.value,
                              attrs: {
                                label: driver.title,
                                value: driver.value
                              }
                            },
                            [_c("span", [_vm._v(" " + _vm._s(driver.title))])]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ringtone.enableDoorbell &&
              _vm.ringtone.doorbellDriver !== "KNX"
                ? _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: {
                        label: _vm.$t("settings.general.doorbell-input")
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("accessory.select-input")
                          },
                          on: { change: _vm.onInputSelect },
                          model: {
                            value: _vm.ringtone.doorbellInput,
                            callback: function($$v) {
                              _vm.$set(_vm.ringtone, "doorbellInput", $$v)
                            },
                            expression: "ringtone.doorbellInput"
                          }
                        },
                        _vm._l(_vm.availableInputs, function(input, index) {
                          return _c(
                            "el-option",
                            {
                              key: input.value,
                              attrs: { label: input.title, value: input.value }
                            },
                            [_c("span", [_vm._v(" " + _vm._s(input.title))])]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ringtone.enableDoorbell &&
              _vm.ringtone.doorbellDriver === "KNX"
                ? _c(
                    "div",
                    { staticClass: "form-item" },
                    [
                      _c("KnxGroupAddress", {
                        attrs: {
                          address: _vm.doorbellKnxAddress,
                          "enable-write-address": false,
                          "enable-write-value": false
                        },
                        on: { changeKnxAddress: _vm.onChangeKnxField }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: { label: _vm.$t("settings.general.doorbell-ringtone") }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-ringtone")
                      },
                      model: {
                        value: _vm.ringtone.doorbellRingtone,
                        callback: function($$v) {
                          _vm.$set(_vm.ringtone, "doorbellRingtone", $$v)
                        },
                        expression: "ringtone.doorbellRingtone"
                      }
                    },
                    _vm._l(_vm.ringtones, function(ringtone, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ringtone.title, value: ringtone.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      on: {
                        click: function($event) {
                          return _vm.onPlaySound(_vm.ringtone.doorbellRingtone)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-play" })]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "300px" },
                  attrs: {
                    label: _vm.$t("settings.general.doorbell-repeat-count")
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      placeholder: _vm.$t("settings.general.repeat-count"),
                      min: "1"
                    },
                    model: {
                      value: _vm.ringtone.doorbellRepeatCount,
                      callback: function($$v) {
                        _vm.$set(_vm.ringtone, "doorbellRepeatCount", $$v)
                      },
                      expression: "ringtone.doorbellRepeatCount"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("settings.general.camera-preview") } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.ringtone.cameraEnabled,
                      callback: function($$v) {
                        _vm.$set(_vm.ringtone, "cameraEnabled", $$v)
                      },
                      expression: "ringtone.cameraEnabled"
                    }
                  })
                ],
                1
              ),
              _vm.ringtone.cameraEnabled
                ? _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("settings.general.camera") } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.ringtone.selectedCameraId,
                            callback: function($$v) {
                              _vm.$set(_vm.ringtone, "selectedCameraId", $$v)
                            },
                            expression: "ringtone.selectedCameraId"
                          }
                        },
                        _vm._l(_vm.ipCameras, function(camera, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: camera.name, value: camera.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.ringtone.cameraEnabled
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("settings.general.camera-duration")
                      }
                    },
                    [
                      _c(
                        "el-input",
                        {
                          staticStyle: { width: "200px" },
                          attrs: {
                            min: "0",
                            type: "number",
                            placeholder: "seconds"
                          },
                          on: { input: _vm.onDurationChanged },
                          model: {
                            value: _vm.ringtone.cameraDuration,
                            callback: function($$v) {
                              _vm.$set(_vm.ringtone, "cameraDuration", $$v)
                            },
                            expression: "ringtone.cameraDuration"
                          }
                        },
                        [
                          _c("template", { slot: "append" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("settings.general.camera-duration-type")
                              )
                            )
                          ])
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", {
                staticClass: "line",
                staticStyle: { height: "2px" }
              }),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "500px" },
                  attrs: { label: _vm.$t("settings.general.intercom-ringtone") }
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-ringtone")
                      },
                      model: {
                        value: _vm.ringtone.intercomRingtone,
                        callback: function($$v) {
                          _vm.$set(_vm.ringtone, "intercomRingtone", $$v)
                        },
                        expression: "ringtone.intercomRingtone"
                      }
                    },
                    _vm._l(_vm.ringtones, function(ringtone, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: ringtone.title, value: ringtone.value }
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "10px" },
                      on: {
                        click: function($event) {
                          return _vm.onPlaySound(_vm.ringtone.intercomRingtone)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-play" })]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }