var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.general.device-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.device
        ? _c(
            "el-form",
            {
              staticClass: "form-settings",
              staticStyle: { width: "500px" },
              attrs: {
                model: _vm.device,
                "label-position": "right",
                "label-width": "200px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("settings.general.device-name") } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("settings.general.type-device-name")
                    },
                    model: {
                      value: _vm.device.name,
                      callback: function($$v) {
                        _vm.$set(_vm.device, "name", $$v)
                      },
                      expression: "device.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("settings.general.language") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-lang")
                      },
                      model: {
                        value: _vm.device.language,
                        callback: function($$v) {
                          _vm.$set(_vm.device, "language", $$v)
                        },
                        expression: "device.language"
                      }
                    },
                    _vm._l(["tr", "en"], function(type, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            type + (type == "tr" ? " (Turkish)" : " (English)"),
                          value: type
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("settings.general.bg-image") }
                        },
                        [
                          _c(
                            "el-upload",
                            {
                              ref: "upload",
                              staticClass: "background-uploader",
                              attrs: {
                                action: "upload.php",
                                "on-success": _vm.handleUploadSuccess,
                                "on-change": _vm.handleChange,
                                "on-remove": _vm.handleRemove,
                                "on-error": _vm.handleError,
                                "auto-upload": false,
                                "file-list": _vm.fileList
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "trigger",
                                    size: "small",
                                    type: "primary"
                                  },
                                  slot: "trigger"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("settings.general.select-file")
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip"
                                },
                                [
                                  _vm.fileList.length == 0 &&
                                  _vm.device.background.name
                                    ? _c("i", {
                                        staticClass:
                                          "fas fa-minus-circle remove-background",
                                        on: { click: _vm.onRemoveBackground }
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.device.background.name)
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("settings.general.blur") } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.device.background.blur,
                              callback: function($$v) {
                                _vm.$set(_vm.device.background, "blur", $$v)
                              },
                              expression: "device.background.blur"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              false
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { staticStyle: { width: "10%" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("settings.general.is-slave")
                              }
                            },
                            [
                              _c("el-switch", {
                                model: {
                                  value: _vm.device.isSlave,
                                  callback: function($$v) {
                                    _vm.$set(_vm.device, "isSlave", $$v)
                                  },
                                  expression: "device.isSlave"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { staticStyle: { width: "90%" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.device.isSlave,
                                  expression: "device.isSlave"
                                }
                              ]
                            },
                            [
                              _c("el-input", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: _vm.ipMask,
                                    expression: "ipMask"
                                  }
                                ],
                                attrs: {
                                  placeholder: _vm.$t(
                                    "settings.general.master-ip"
                                  )
                                },
                                model: {
                                  value: _vm.device.masterIp,
                                  callback: function($$v) {
                                    _vm.$set(_vm.device, "masterIp", $$v)
                                  },
                                  expression: "device.masterIp"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: _vm.$t("alert.title.warning"),
                    visible: _vm.showDialog
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.showDialog = $event
                    },
                    visiblechange: _vm.updateShowDialog
                  }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.general.slave-mode-message"))
                    )
                  ]),
                  _c(
                    "span",
                    { attrs: { slot: "footer" }, slot: "footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.showDialog = false
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("alert.button.cancel")))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveDeviceSettings }
                        },
                        [_vm._v(_vm._s(_vm.$t("alert.button.ok")))]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c("el-alert", {
                staticClass: "alert-image",
                attrs: {
                  title: _vm.$t("settings.general.select-file-warning"),
                  type: "info",
                  closable: false,
                  "show-icon": ""
                }
              }),
              _c("span")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }