var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "root-layout" },
    [
      _c("app-navigation", {
        attrs: { title: _vm.$t("settings.general.general-settings") }
      }),
      _vm.loading
        ? _c("div", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { width: "100%", height: "100%" }
          })
        : _vm._e(),
      _c("project-settings", { attrs: { loading: _vm.loading } }),
      _c("device-settings", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.$store.getters.isConnectedDevice,
            expression: "!$store.getters.isConnectedDevice"
          }
        ],
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _c("device-settings-remote", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.getters.isConnectedDevice,
            expression: "$store.getters.isConnectedDevice"
          }
        ],
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _c("screensaver-settings", {
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _c("weather-settings", {
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _c("ringtone-settings", {
        ref: "ringtoneForm",
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _c("date-time-settings", {
        staticStyle: { "margin-top": "30px" },
        attrs: { loading: _vm.loading }
      }),
      _vm.existsKNXTP
        ? _c("knx-settings", {
            staticStyle: { "margin-top": "30px" },
            attrs: { loading: _vm.loading }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }