<template>
  <div class="layout-settings">
    <div class="form-title">
      {{ $t("settings.general.ringtone-settings") }}
      <span
        v-if="showSaveButton"
        style="float: right; cursor: pointer"
        @click="onSave"
        ><i class="fa fa-upload"></i> {{ $t("settings.general.save") }}</span
      >
    </div>

    <el-form
      v-if="ringtone"
      ref="projectData"
      :model="ringtone"
      label-position="right"
      label-width="200px"
      class="form-settings"
    >
      <el-form-item
        :label="$t('settings.general.doorbell-enable')"
        style="width: 500px"
      >
        <el-switch
          v-model="ringtone.enableDoorbell"
          @change="onDoorbellStateChanged"
          :disabled="availableInputs.length <= 0"
          :active-text="
            availableInputs.length > 0
              ? ''
              : $t('settings.general.no-iddle-port')
          "
        ></el-switch>
      </el-form-item>

      <el-form-item
        v-if="ringtone.enableDoorbell"
        :label="$t('settings.general.doorbell-driver')"
        style="width: 500px"
      >
        <el-select
          v-model="ringtone.doorbellDriver"
          @change="onDriverSelect"
          :placeholder="$t('accessory.select-driver')"
        >
          <el-option
            v-for="(driver, index) in doorbellDrivers"
            :label="driver.title"
            :value="driver.value"
            :key="driver.value"
          >
            <span> {{ driver.title }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="ringtone.enableDoorbell && ringtone.doorbellDriver !== 'KNX'"
        :label="$t('settings.general.doorbell-input')"
        style="width: 500px"
      >
        <el-select
          v-model="ringtone.doorbellInput"
          @change="onInputSelect"
          :placeholder="$t('accessory.select-input')"
        >
          <el-option
            v-for="(input, index) in availableInputs"
            :label="input.title"
            :value="input.value"
            :key="input.value"
          >
            <span> {{ input.title }}</span>
          </el-option>
        </el-select>
      </el-form-item>

      <div
        class="form-item"
        v-if="ringtone.enableDoorbell && ringtone.doorbellDriver === 'KNX'"
      >
        <KnxGroupAddress
          @changeKnxAddress="onChangeKnxField"
          :address="doorbellKnxAddress"
          :enable-write-address="false"
          :enable-write-value="false"
        ></KnxGroupAddress>
      </div>

      <el-form-item
        :label="$t('settings.general.doorbell-ringtone')"
        style="width: 500px"
      >
        <el-select
          v-model="ringtone.doorbellRingtone"
          filterable
          :placeholder="$t('settings.general.select-ringtone')"
          style="width: 200px"
        >
          <el-option
            v-for="(ringtone, index) in ringtones"
            :key="index"
            :label="ringtone.title"
            :value="ringtone.value"
          ></el-option>
        </el-select>

        <el-button
          style="margin-left: 10px"
          @click="onPlaySound(ringtone.doorbellRingtone)"
          ><i class="fa fa-play"></i
        ></el-button>
      </el-form-item>

      <el-form-item
        :label="$t('settings.general.doorbell-repeat-count')"
        style="width: 300px"
      >
        <el-input
          v-model="ringtone.doorbellRepeatCount"
          type="number"
          :placeholder="$t('settings.general.repeat-count')"
          min="1"
        />
      </el-form-item>

      <el-form-item :label="$t('settings.general.camera-preview')">
        <el-switch v-model="ringtone.cameraEnabled" />
      </el-form-item>

      <el-form-item
        v-if="ringtone.cameraEnabled"
        :label="$t('settings.general.camera')"
      >
        <el-select v-model="ringtone.selectedCameraId">
          <el-option
            v-for="(camera, index) in ipCameras"
            :key="index"
            :label="camera.name"
            :value="camera.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="ringtone.cameraEnabled"
        :label="$t('settings.general.camera-duration')"
      >
        <el-input
          min="0"
          @input="onDurationChanged"
          type="number"
          placeholder="seconds"
          v-model="ringtone.cameraDuration"
          style="width: 200px"
        >
          <template slot="append">{{
            $t("settings.general.camera-duration-type")
          }}</template>
        </el-input>
      </el-form-item>

      <div class="line" style="height: 2px" />

      <el-form-item
        :label="$t('settings.general.intercom-ringtone')"
        style="width: 500px"
      >
        <el-select
          v-model="ringtone.intercomRingtone"
          filterable
          :placeholder="$t('settings.general.select-ringtone')"
          style="width: 200px"
        >
          <el-option
            v-for="(ringtone, index) in ringtones"
            :key="index"
            :label="ringtone.title"
            :value="ringtone.value"
          ></el-option>
        </el-select>
        <el-button
          style="margin-left: 10px"
          @click="onPlaySound(ringtone.intercomRingtone)"
          ><i class="fa fa-play"></i
        ></el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import KnxGroupAddress from "@/views/accessory/components/knx/KnxGroupAddress";
export default {
  name: "RingtoneSettings",

  components: {
    KnxGroupAddress,
  },

  props: {
    loading: Boolean,
  },

  data: function () {
    return {
      /*ringtone: {
          cameraEnabled: false,
          doorbellInput: "",
          doorbellRepeatCount: 1,
          doorbellRingtone: "",
          doorbellSoundLevel: 7,
          enableDoorbell: false,
          intercomRingtone: "",
          intercomSoundLevel: 80
        },*/

      ringtones: [
        {
          title: this.$t("settings.general.ringtones[0]"),
          value: "door_bell_2_25",
        },
        {
          title: this.$t("settings.general.ringtones[1]"),
          value: "door_bell_3_35",
        },
        {
          title: this.$t("settings.general.ringtones[2]"),
          value: "door_bell_echo_10",
        },
        {
          title: this.$t("settings.general.ringtones[3]"),
          value: "door_bell_tiny_bells_40",
        },
        {
          title: this.$t("settings.general.ringtones[4]"),
          value: "nice_bells_35",
        },
        {
          title: this.$t("settings.general.ringtones[5]"),
          value: "office_ring_small",
        },
      ],
      showSaveButton: false,
      cameraEnabled: false,
      ipCameras: [],
      inputs: [
        { title: this.$t("accessory.input") + " " + 1, value: "118" },
        { title: this.$t("accessory.input") + " " + 2, value: "120" },
        { title: this.$t("accessory.input") + " " + 3, value: "121" },
        { title: this.$t("accessory.input") + " " + 4, value: "122" },
        { title: this.$t("accessory.input") + " " + 5, value: "51" },
      ],
      doorbellDrivers: [
        { title: "Input", value: "Input" },
        { title: "KNX", value: "KNX" },
      ],
      doorbellKnxAddress: {
        variable: "doorbellRinging",
        dpt: "1.001",
        readAddress: "",
        activeValue: "",
      },
    };
  },

  computed: {
    ringtone: function () {
      return this.$store.getters.generalSettingsFormData.ringtone;
    },

    availableInputs: function () {
      const usedInputs = this.$store.getters.accessories
        .filter(
          (accessory) =>
            this.$store.getters.drivers.find(
              (driver) => driver.id == accessory.driverId
            ).driverType == "GPIO"
        )
        .filter((accessory) => accessory.group == 2)
        .map((accessory) => accessory.details.id);
      console.log("usedInputs", usedInputs);

      return this.inputs.filter((input) => !usedInputs.includes(input.value));
    },
  },

  watch: {
    ringtone: {
      handler: function (val) {
        console.log(val);
        this.showSaveButton = true;
      },
      deep: true,
    },

    doorbellKnxAddress: {
      handler: function (val) {
        console.log(val);
        this.showSaveButton = true;
      },
      deep: true,
    },

    loading: function (newVal, oldVal) {
      if (!newVal) this.showSaveButton = false;
    },
  },

  methods: {
    onChangeKnxField(variable, msg) {
      console.log("onChangeKnxField", variable, msg)
      console.log("onChangeKnxField2", this.doorbellKnxAddress)
    },
    onInputSelect() {
      /*console.log(this.doorbellInput)
        this.showSaveButton = true
        this.ringtone.doorbellInput = this.doorbellInput*/
    },
    onDriverSelect() {
      /*console.log(this.doorbellInput)
        this.showSaveButton = true
        this.ringtone.doorbellInput = this.doorbellInput*/
    },
    onDoorbellStateChanged(enabled) {
      if (!enabled) {
        this.ringtone.cameraEnabled = enabled;
        delete this.ringtone.doorbellInput;
      } else {
        if (!this.ringtone.doorbellInput) {
          //this.doorbellInput = this.availableInputs[0].value
          this.ringtone.doorbellInput = this.availableInputs[0].value;
        } else {
          //this.doorbellInput = this.ringtone.doorbellInput
        }
      }

      //console.log(this.ringtone)
    },

    onSave: function () {
      if (this.ringtone.doorbellRepeatCount < 1) {
        this.ringtone.doorbellRepeatCount = 1;
      }

      if (this.ringtone.doorbellDriver === "KNX") {
        this.ringtone.doorbellKnxAddress = this.doorbellKnxAddress;
      }

      this.$store
        .dispatch("saveGeneralSettings", {
          ...this.$store.getters.generalSettingsFormData,
          ringtone: { ...this.ringtone },
        })
        .then((response) => {
          this.$message({
            type: "success",
            message: this.$t(response.message),
          });
          this.showSaveButton = false;
        })
        .catch((reason) => {
          this.$message({ type: "error", message: this.$t(reason) });
          this.showSaveButton = false;
        });
    },

    onPlaySound(ringtone) {
      this.$store.dispatch("playSound", ringtone);
    },

    getItemTitle: function (index) {
      return this.$t("accessory.input") + " " + (index + 1);
    },

    onDurationChanged(value) {
      if (value < 0) {
        this.ringtone.cameraDuration = 0;
      }
    },

    onLoad() {
      //this.ringtone = {...this.$store.getters.generalSettingsFormData.ringtone}

      if (!this.ringtone.doorbellDriver) {
        this.ringtone.doorbellDriver = "Input"
      }

      if (this.ringtone.doorbellKnxAddress) {
        this.doorbellKnxAddress = {...this.ringtone.doorbellKnxAddress}
      }

      if (this.availableInputs.length > 0 && !this.ringtone.doorbellInput) {
        //this.doorbellInput = this.availableInputs[0].value
        this.ringtone.doorbellInput = this.availableInputs[0].value;
      } else {
        //this.doorbellInput = this.ringtone.doorbellInput
      }

      console.log("onLoad, availableInputs", this.availableInputs);
    },
  },

  created() {
    this.$store
      .dispatch("getIPCameras")
      .then((message) => {
        console.log(message);
        this.ipCameras = this.$store.getters.ipcameras;
        if (this.ipCameras.length == 0 && this.ringtone.cameraEnabled) {
          this.ringtone.cameraEnabled = false;
          this.ringtone.selectedCameraId = null;
          this.ringtone.cameraDuration = null;
        }
      })
      .catch((reason) => {
        console.log(reason);
      });

    this.$store.dispatch("getAccessories").then((msg) => {});
  },
};
</script>

<style scoped>
@import "../../css/general.css";

.line {
  height: 1px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #eeeeee;
}

.form-item {
  margin: 10px;
  margin-bottom: 30px;
  padding: 10px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  color: #606266;
}
.form-item span {
  font-weight: bold;
}
</style>