var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-settings" },
    [
      _c("div", { staticClass: "form-title" }, [
        _vm._v(_vm._s(_vm.$t("settings.general.weather-settings")) + " "),
        _vm.showSaveButton
          ? _c(
              "span",
              {
                staticStyle: { float: "right", cursor: "pointer" },
                on: { click: _vm.onSave }
              },
              [
                _c("i", { staticClass: "fa fa-upload" }),
                _vm._v(" " + _vm._s(_vm.$t("settings.general.save")))
              ]
            )
          : _vm._e()
      ]),
      _vm.weather
        ? _c(
            "el-form",
            {
              staticClass: "form-settings",
              staticStyle: { width: "500px" },
              attrs: {
                model: _vm.weather,
                "label-position": "right",
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("settings.general.weather-type") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        placeholder: _vm.$t("settings.general.select-type")
                      },
                      model: {
                        value: _vm.weather.unit,
                        callback: function($$v) {
                          _vm.$set(_vm.weather, "unit", $$v)
                        },
                        expression: "weather.unit"
                      }
                    },
                    _vm._l(["f", "c"], function(type, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label:
                            type == "f"
                              ? _vm.$t("settings.general.fahrenheit")
                              : _vm.$t("settings.general.centigrade"),
                          value: type
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("settings.general.polling-period") } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "number",
                        min: 5,
                        placeholder: _vm.$t("settings.general.type-period")
                      },
                      model: {
                        value: _vm.weather.pollingPeriod,
                        callback: function($$v) {
                          _vm.$set(_vm.weather, "pollingPeriod", $$v)
                        },
                        expression: "weather.pollingPeriod"
                      }
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "append" }, slot: "append" },
                        [_vm._v(_vm._s(_vm.$t("settings.general.min")))]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }