<template>
  <div class="layout-settings">

    <div class="form-title">{{ $t('settings.general.knx-settings') }} <span v-if="showSaveButton" style="float: right; cursor: pointer" @click="onSave"><i class="fa fa-upload"></i> {{ $t('settings.general.save') }}</span></div>

    <el-form v-if="knxSettings" ref="projectData" :model="knxSettings" label-position="right" label-width="150px" class="form-settings">

      <el-form-item v-if="false" :label="$t('settings.general.discard-knx-bus-errors')" style="width: 500px">

        <el-switch v-model="knxSettings.discardBusErrors"></el-switch>

      </el-form-item>


      <el-form-item :label="$t('settings.general.source-address')" style="width: 300px">
        <el-input v-model="knxSettings.tpuartSourceAddress" type="text" placeholder="x.y.z" v-mask="groupAddressMask"/>
      </el-form-item>


    </el-form>

  </div>
</template>

<script>
  export default {
    name: "KnxSettings",


    computed: {
      knxSettings: function () {
        return this.$store.getters.generalSettingsFormData.knxSettings
      }
    },

    watch: {
      knxSettings: {
        handler: function (val) {
          this.showSaveButton = true
        },
        deep: true
      },
      loading: function (newVal, oldVal) {
        if (!newVal)
          this.showSaveButton = false
      }
    },

    props: {
      loading: Boolean
    },

    data: function () {
      return {
        showSaveButton: false,
        groupAddressMask: {
          mask: "9{1,3}.9{1,3}.9{1,3}"
        },
      }
    },

    methods: {
      onSave: function () {
        var error = true
        if (this.knxSettings.tpuartSourceAddress) {
          const addrItems = this.knxSettings.tpuartSourceAddress.split(".")
          if (addrItems.length == 3) {
            const x = parseInt(addrItems[0])
            const y = parseInt(addrItems[1])
            const z = parseInt(addrItems[2])

            if (x <= 15 && y <= 15 && z <= 255) {

              error = false

              this.$store.dispatch("saveGeneralSettings", {...this.$store.getters.generalSettingsFormData,
                knxSettings: {...this.knxSettings}}).then(response => {

                this.$message({ type: "success", message: this.$t(response.message) })
                this.showSaveButton = false
              }).catch(reason => {
                this.$message({ type: "error", message: this.$t(reason) })
                this.showSaveButton = false
              })

            }
          }
        }

        if (error) {
          this.$message({ type: "error", message: this.$t('settings.general.error-source-address') })
        }
      },

    },

  }
</script>

<style scoped>
  @import "../../css/general.css";
</style>